@import url(https://fonts.googleapis.com/css2?family=Abel&family=Teko:wght@300;400;500;600;700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Catamaran:wght@200;300;400;500;600;700;800&family=Orbitron:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Catamaran:wght@200;300;400;500;600;700;800&family=Caveat:wght@400;500;600;700&family=Orbitron:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Catamaran', sans-serif;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  25% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  75% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  25% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  75% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

.detailsS {
  color: rgb(250, 156, 156);
}

.page-loader {
  width: 100%;
  height: 100vh;
  background: #000000;
  z-index: 200000000000000;
  position: fixed;
}

.page-loader .txt {
  color: #2b98ff;
  text-shadow: black 3px 5px 2px;
  font-family: 'Orbitron', sans-serif;
  letter-spacing: 2px;
  text-align: center;
  top: 40%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  line-height: 1.5;
  font-size: 30px;
}

.spinner {
  position: relative;
  top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}


::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d86b6b;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(174, 216, 250);
}

.container {
  /* Add a height and scrolling mechanism to the container */
  height: 300px;
  /* You can adjust the height as needed */
  overflow: auto;
  /* This allows the container to scroll */

  /* Position the image at the bottom of the container */
  position: relative;
}

@media screen and (max-width: 499px) {

  .connect2btns {
    display: flex;
  }

  .mm {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-left: 20px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .connect2-2-Mob-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-left: 20px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 2px;
  }

  .details {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #252525bb;
    padding: 15px;
    border-radius: 10px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #00000000;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000000000000000000;
    margin-left: 30px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #ff5144 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #fa1e0e solid;
    -webkit-animation: fadeInLeft 0.6s forwards;
            animation: fadeInLeft 0.6s forwards;
  }

  @-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }


  .typeH {
    font-size: 18px;
  }

  .musicOn {
    position: -webkit-sticky;
    position: sticky;
    bottom: 20px;
    width: 80px;
    height: 80px;
    margin-left: 50px;
    cursor: pointer;
    -webkit-animation: bounce 3s ease infinite;
            animation: bounce 3s ease infinite;
  }


  .rock {
    position: -webkit-sticky;
    position: sticky;
    bottom: 5%;
    width: 15%;
    margin-left: 30px;
  }

  .stickyimg {
    position: -webkit-sticky;
    position: sticky;
    bottom: 80px;
    width: 40%;
    margin-left: 60%;
    justify-content: flex-end;
    -webkit-animation: floating 2.5s ease-in-out infinite;
            animation: floating 2.5s ease-in-out infinite;
  }

  .stickyGuitar {
    /* Make the image sticky at the bottom */
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 100%;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .priceBox {
    background-color: #00000075;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 25px;
    border-radius: 20px;
    border: 2px dashed #eb2f0e;
  }

  .quotes {
    color: #ebc95a;
    font-size: 22px;
    font-family: 'Caveat', cursive;

  }

  .prices {
    text-align: center;
    color: #b4e3ff;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pricesN {
    text-align: center;
    color: rgba(207, 207, 207, 0.61);
    font-style: italic;
    font-size: 18px;
  }

  .poly {
    width: 25px;
    height: 25px;
    margin-left: 10px;

  }

  .note {
    text-align: center;
    color: red;
    font-size: 20px;
  }

  .mainImg {
    width: 70%;
    height: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 14px;
    font-family: 'Catamaran', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 157, 157);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 35px;
    margin-right: 35px;
  }

  .connectBtn {
    background-color: #beb41f00;
    color: rgb(255, 255, 255);
    padding: 10px;
    font-size: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 5px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #3c9af3);
    border-image-slice: 1;
    display: flex;
  }

  .connectBtn:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: rgb(0, 0, 0);
    background: linear-gradient(315deg, rgba(0, 0, 0, 1) 0%, rgba(41, 1, 4, 1) 50%, rgba(0, 0, 0, 1) 59%, rgba(56, 0, 4, 1) 100%);
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/logo.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-bottom: 7vh;
    margin-top: 7vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    width: 100px;
    height: 100px;
    display: none;
    border-radius: 100px;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #ee1d1d, #fd3b3b);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.3rem;
    font-size: 25px;
    color: #fdba00;
    letter-spacing: 2px;
    font-family: 'Orbitron', sans-serif;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 10rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 10rem;
    line-height: 10rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    padding-top: 15%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/fire.b98ac362.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
    background-size: cover;
  }

  .boxWrap2Mint {

    background: rgb(61, 3, 3);
    background: radial-gradient(circle, rgba(61, 3, 3, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(13, 0, 2, 1) 100%);
    background-size: cover;
    padding-bottom: 7%;
    padding-top: 7%;

  }

  .boxWrapNFT {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #000000;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 10%;
    padding-bottom: 7%;
    background: rgb(89, 4, 35);
    background: radial-gradient(circle, rgba(89, 4, 35, 0.5178243172268908) 0%, rgba(222, 6, 86, 0.15367865896358546) 50%, rgba(0, 0, 0, 0) 100%);
  }


  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  iframe {
    height: 70vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    margin-top: 30px;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 100px;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 20px;
    border: 2px solid transparent;
    -webkit-animation: borderGlow 2s infinite;
            animation: borderGlow 2s infinite;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #ff5c5c);
    border-image-slice: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 142, 236);
    background: linear-gradient(117deg, rgba(0, 142, 236, 0.09485512955182074) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(24, 4, 7, 1) 100%);
  }

  .about2-2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  @-webkit-keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  .conT {
    font-size: 40px;
    color: rgb(255, 255, 255);
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT2 {
    font-size: 40px;
    color: white;
    text-align: center;
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .listContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .listContainer2 {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .listMain {
    margin-bottom: 8%;
    margin-left: auto;
    justify-content: center;
    width: 90%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: -5px 5px black;
    border: 1px solid black;
    padding: 20px;
    transition: box-shadow 0.3s ease;
  }

  .listMain:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .listH {
    font-size: 23px;
    color: #ffd415;
    letter-spacing: 2px;
    text-align: center;
    text-shadow: 0 0 10px #e6414198;
  }

  .listCon {
    font-size: 18px;
    color: rgb(253, 245, 175);

    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .in1 {
    font-family: 'Orbitron', sans-serif;
    text-align: center;
    font-size: 25px;
    color: #ffffff;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff9c;
  }

  .in2 {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    margin-bottom: 6%;
    font-family: 'Caveat', cursive;
    line-height: 28px;

  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #002bff,
        #7a00ff,
        #ff00c8,
        #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .introduction2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .hl {
    font-family: 'Orbitron', sans-serif;
    color: #ffffff;
  }

  .download {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
  }

  .explore {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 30px;
    border: none;
    background-color: #0084ff;
    box-shadow: -3px 3px #01519c;
    color: white;
    border-radius: 100px;
    padding: 10px;
  }

  .explore:hover {
    -webkit-animation: shake 0.3s ease-in-out;
            animation: shake 0.3s ease-in-out;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }


  .storyCon {
    font-size: 16px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
  }

  .nameT {
    font-size: 22px;
    color: rgb(250, 48, 48);
    text-align: center;
    margin-top: 20px;
  }

  .positionT {
    font-size: 15px;
    color: rgb(255, 255, 255);
    text-align: center;
    letter-spacing: 1px;
  }

  .teamMain {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
    margin-top: 5%;
  }

  .teamMain img {
    width: 210px;
    border-radius: 100%;
    border: 2px #964835 solid;
    border: 2px #964835 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 10%;
  }

  @-webkit-keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }

  @keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }


  .stripe {
    width: 100%;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 20px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 1px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .type {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 5%;
  }

  .bgC {
    background-color: #141414;
    border-radius: 50px;
    padding: 15px;
    border: 1px solid white;
    box-shadow: rgba(243, 230, 224, 0.5) 0 3px 8px;
    width: 100%;
  }

  .typeCon {
    color: rgb(253, 175, 175);

    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }

  .imagesNormal {
    justify-content: space-evenly;
    width: 100%;
  }

  .imagesNormalDiv {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
  }

  .imagesNormal img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .imagesNormal img:hover {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    margin-top: 7%;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border: 2px solid white;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 60%;
  }

  .selectedImg2 {
    background-image: url('https://gif-4qi.pages.dev/images/gif.gif');
    background-size: contain;
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    z-index: 10000000;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .price {
    text-align: center;
    font-size: 16px;
    color: rgb(255, 255, 255);
    z-index: 10000000;

    letter-spacing: 2px;
    cursor: pointer;
  }

  .price:hover {
    color: #0084ff;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 20px;
    margin-left: 40px;
    margin-right: 40px;
    width: 50px;
    height: 50px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #ffee0000;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: #ffffff 2px solid;
    background-color: #ffee0000;
    transition: box-shadow 0.3s ease;
  }

  .btnfos-0-2:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }


  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 90%;
    background-color: #ffee0000;
    border: 1px solid #ffffff;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: box-shadow 0.3s ease;
    display: none;
  }

  .btnfos-0-3-mob {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 90%;
    background-color: #ffee0000;
    border: 1px solid #ffffff;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: box-shadow 0.3s ease;
  }


  /* CSS */
  .button-42 {
    background-color: transparent;
    background-color: initial;
    background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
    height: 40px;
    line-height: 40px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
    transition: box-shadow .2s;
  }

  .button-42:hover {
    box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
  }

  .btnfos-0-3:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  #dogSelect1 {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    background-color: #000000ab;
    color: white;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    cursor: pointer;
  }

  #dogSelect {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    background-color: #000000ab;
    color: white;

    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 300px;
    /* Set a maximum height for the container */
    -webkit-animation-name: pop;
            animation-name: pop;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  @-webkit-keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .down {
    width: 20px;
    height: 20px;
    margin-left: 10px;

  }

  #dogSelect img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  #dogSelect div {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;

    margin-top: auto;
    margin-bottom: auto;
  }

  .textH:hover {
    color: #009ab8;
    cursor: pointer;
  }

  .selectImg {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .selectImg:hover {
    background-color: rgba(128, 128, 128, 0.644);
  }

  select {}


  .nftamount {
    color: #ffffff;
    font-size: 70px;
    font-family: 'Catamaran', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #17ff1e;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Catamaran', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 22px;
    color: #ffffff;
    text-align: center;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 220px;
    height: 220px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }


  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 164, 164);
    font-size: 19px;
    font-family: 'Caveat', cursive;
    margin-top: 10px;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Catamaran', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    font-family: 'Catamaran', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 20px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  table {
    border-collapse: collapse;
    width: 90%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .tableMain {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  table img {
    width: 100px;
    height: 100px;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #4d4d4d86;
    color: #ffffff;

    font-size: 18px;
    position: relative;
  }

  tr:nth-child(even) {
    background-color: #4d4d4d86;
  }

  th {
    background-color: #202020;
    color: #2491f7;
    font-size: 20px;
  }

  /* Show the hover images when hovering over the table cells */
  .hoverDiv img {
    width: 200px;
    height: 200px;
  }

  .hoverDiv {
    display: flex;
    justify-content: space-evenly;
  }

  .sticky {
    width: 20%;
  }

  .stickyDiv {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;

  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .connect2btns {
    display: flex;
  }

  .connect2-2-Mob-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 2px;
  }

  .mm {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .details {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #252525bb;
    padding: 15px;
    border-radius: 10px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #00000000;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000000000000000000;
    margin-top: 28px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #ff5144 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #fa1e0e solid;
    -webkit-animation: fadeInLeft 0.6s forwards;
            animation: fadeInLeft 0.6s forwards;
  }

  @-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }


  .typeH {
    font-size: 18px;
  }

  .musicOn {
    position: -webkit-sticky;
    position: sticky;
    bottom: 20px;
    width: 80px;
    height: 80px;
    margin-left: 50px;
    cursor: pointer;
    -webkit-animation: bounce 3s ease infinite;
            animation: bounce 3s ease infinite;
  }


  .rock {
    position: -webkit-sticky;
    position: sticky;
    bottom: 5%;
    width: 15%;
    margin-left: 30px;
  }

  .stickyimg {
    position: -webkit-sticky;
    position: sticky;
    bottom: 80px;
    width: 40%;
    margin-left: 60%;
    justify-content: flex-end;
    -webkit-animation: floating 2.5s ease-in-out infinite;
            animation: floating 2.5s ease-in-out infinite;
  }

  .stickyGuitar {
    /* Make the image sticky at the bottom */
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 100%;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .priceBox {
    background-color: #00000075;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 25px;
    border-radius: 20px;
    border: 2px dashed #eb2f0e;
  }

  .quotes {
    color: #ebc95a;
    font-size: 22px;
    font-family: 'Caveat', cursive;

  }

  .prices {
    text-align: center;
    color: #b4e3ff;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pricesN {
    text-align: center;
    color: rgba(207, 207, 207, 0.61);
    font-style: italic;
    font-size: 18px;
  }

  .poly {
    width: 25px;
    height: 25px;
    margin-left: 10px;

  }

  .note {
    text-align: center;
    color: red;
    font-size: 20px;
  }

  .mainImg {
    width: 70%;
    height: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 14px;
    font-family: 'Catamaran', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 157, 157);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 35px;
    margin-right: 35px;
  }

  .connectBtn {
    background-color: #beb41f00;
    color: rgb(255, 255, 255);
    padding: 10px;
    font-size: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 5px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #3c9af3);
    border-image-slice: 1;
    display: flex;
  }

  .connectBtn:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: rgb(0, 0, 0);
    background: linear-gradient(315deg, rgba(0, 0, 0, 1) 0%, rgba(41, 1, 4, 1) 50%, rgba(0, 0, 0, 1) 59%, rgba(56, 0, 4, 1) 100%);
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/logo.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-bottom: 7vh;
    margin-top: 5vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 7rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #ee1d1d, #fd3b3b);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 7rem;
    line-height: 7rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.3rem;
    font-size: 30px;
    color: #fdba00;
    letter-spacing: 2px;
    font-family: 'Orbitron', sans-serif;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 10rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 10rem;
    line-height: 10rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    padding-top: 15%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/fire.b98ac362.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
    background-size: cover;
  }

  .boxWrap2Mint {

    background: rgb(61, 3, 3);
    background: radial-gradient(circle, rgba(61, 3, 3, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(13, 0, 2, 1) 100%);
    background-size: cover;
    padding-bottom: 7%;
    padding-top: 7%;

  }

  .boxWrapNFT {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #000000;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 10%;
    padding-bottom: 7%;
    background: rgb(89, 4, 35);
    background: radial-gradient(circle, rgba(89, 4, 35, 0.5178243172268908) 0%, rgba(222, 6, 86, 0.15367865896358546) 50%, rgba(0, 0, 0, 0) 100%);
  }


  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  iframe {
    height: 70vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    margin-top: 30px;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 100px;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 20px;
    border: 2px solid transparent;
    -webkit-animation: borderGlow 2s infinite;
            animation: borderGlow 2s infinite;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #ff5c5c);
    border-image-slice: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 142, 236);
    background: linear-gradient(117deg, rgba(0, 142, 236, 0.09485512955182074) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(24, 4, 7, 1) 100%);
  }

  .about2-2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  @-webkit-keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  .conT {
    font-size: 50px;
    color: rgb(255, 255, 255);
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT2 {
    font-size: 50px;
    color: white;
    text-align: center;
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .listContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .listContainer2 {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .listMain {
    margin-bottom: 8%;
    margin-left: auto;
    justify-content: center;
    width: 90%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: -5px 5px black;
    border: 1px solid black;
    padding: 20px;
    transition: box-shadow 0.3s ease;
  }

  .listMain:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .listH {
    font-size: 23px;
    color: #ffd415;
    letter-spacing: 2px;
    text-align: center;
    text-shadow: 0 0 10px #e6414198;
  }

  .listCon {
    font-size: 18px;
    color: rgb(253, 245, 175);

    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .in1 {
    font-family: 'Orbitron', sans-serif;
    text-align: center;
    font-size: 30px;
    color: #ffffff;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff9c;
  }

  .in2 {
    text-align: center;
    font-size: 22px;
    color: #ffffff;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    margin-bottom: 6%;
    font-family: 'Caveat', cursive;
    line-height: 28px;

  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #002bff,
        #7a00ff,
        #ff00c8,
        #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .introduction2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .hl {
    font-family: 'Orbitron', sans-serif;
    color: #ffffff;
  }

  .download {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
  }

  .explore {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 30px;
    border: none;
    background-color: #0084ff;
    box-shadow: -3px 3px #01519c;
    color: white;
    border-radius: 100px;
    padding: 10px;
  }

  .explore:hover {
    -webkit-animation: shake 0.3s ease-in-out;
            animation: shake 0.3s ease-in-out;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }


  .storyCon {
    font-size: 16px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
  }

  .nameT {
    font-size: 22px;
    color: rgb(250, 48, 48);
    text-align: center;
    margin-top: 20px;
  }

  .positionT {
    font-size: 15px;
    color: rgb(255, 255, 255);
    text-align: center;
    letter-spacing: 1px;
  }

  .teamMain {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
    margin-top: 5%;
  }

  .teamMain img {
    width: 250px;
    border-radius: 100%;
    border: 2px #964835 solid;
    border: 2px #964835 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 10%;
  }

  @-webkit-keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }

  @keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }


  .stripe {
    width: 100%;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 20px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 1px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .type {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 5%;
  }

  .bgC {
    background-color: #141414;
    border-radius: 50px;
    padding: 15px;
    border: 1px solid white;
    box-shadow: rgba(243, 230, 224, 0.5) 0 3px 8px;
  }

  .typeCon {
    color: rgb(253, 175, 175);

    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }

  .imagesNormal {
    justify-content: space-evenly;
    width: 100%;
  }

  .imagesNormalDiv {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
  }

  .imagesNormal img {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .imagesNormal img:hover {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    margin-top: 7%;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border: 2px solid white;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 60%;
  }

  .selectedImg2 {
    background-image: url('https://gif-4qi.pages.dev/images/gif.gif');
    background-size: contain;
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    z-index: 10000000;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: rgb(255, 255, 255);
    z-index: 10000000;

    letter-spacing: 2px;
    cursor: pointer;
  }

  .price:hover {
    color: #0084ff;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 30px;
    margin-left: 50px;
    margin-right: 50px;
    width: 60px;
    height: 60px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #ffee0000;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: #ffffff 2px solid;
    background-color: #ffee0000;
    transition: box-shadow 0.3s ease;
  }

  .btnfos-0-2:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }


  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 300px;
    background-color: #ffee0000;
    border: 1px solid #ffffff;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: box-shadow 0.3s ease;
    display: none;
  }

  .btnfos-0-3-mob {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 300px;
    background-color: #ffee0000;
    border: 1px solid #ffffff;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: box-shadow 0.3s ease;
  }


  /* CSS */
  .button-42 {
    background-color: transparent;
    background-color: initial;
    background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
    height: 40px;
    line-height: 40px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
    transition: box-shadow .2s;
  }

  .button-42:hover {
    box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
  }

  .btnfos-0-3:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  #dogSelect1 {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    background-color: #000000ab;
    color: white;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    cursor: pointer;
  }

  #dogSelect {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    background-color: #000000ab;
    color: white;

    width: 45%;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 300px;
    /* Set a maximum height for the container */
    -webkit-animation-name: pop;
            animation-name: pop;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  @-webkit-keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .down {
    width: 20px;
    height: 20px;
    margin-left: 10px;

  }

  #dogSelect img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  #dogSelect div {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;

    margin-top: auto;
    margin-bottom: auto;
  }

  .textH:hover {
    color: #009ab8;
    cursor: pointer;
  }

  .selectImg {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .selectImg:hover {
    background-color: rgba(128, 128, 128, 0.644);
  }

  select {}


  .nftamount {
    color: #ffffff;
    font-size: 80px;
    font-family: 'Catamaran', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #17ff1e;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Catamaran', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 22px;
    color: #ffffff;
    text-align: center;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 40px;
    height: 40px;
    margin-left: 15px;
    margin-right: 15px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 220px;
    height: 220px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }


  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 164, 164);
    font-size: 19px;
    font-family: 'Caveat', cursive;
    margin-top: 10px;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Catamaran', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    font-family: 'Catamaran', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 20px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  table {
    border-collapse: collapse;
    width: 90%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .tableMain {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  table img {
    width: 100px;
    height: 100px;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #4d4d4d86;
    color: #ffffff;

    font-size: 18px;
    position: relative;
  }

  tr:nth-child(even) {
    background-color: #4d4d4d86;
  }

  th {
    background-color: #202020;
    color: #2491f7;
    font-size: 20px;
  }

  /* Show the hover images when hovering over the table cells */
  .hoverDiv img {
    width: 200px;
    height: 200px;
  }

  .hoverDiv {
    display: flex;
    justify-content: space-evenly;
  }

  .sticky {
    width: 20%;
  }

  .stickyDiv {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;

  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .connect2btns {
    display: flex;
  }

  .connect2-2-Mob-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-left: 20px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 2px;
  }

  .mm {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-left: 20px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10px;
    margin-left: 10px;
  }


  .details {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #252525bb;
    padding: 15px;
    border-radius: 10px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #00000000;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000000000000000000;
    margin-top: 28px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #ff5144 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #fa1e0e solid;
    -webkit-animation: fadeInLeft 0.6s forwards;
            animation: fadeInLeft 0.6s forwards;
  }

  @-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }


  .typeH {
    font-size: 22px;
  }

  .musicOn {
    position: -webkit-sticky;
    position: sticky;
    bottom: 80px;
    width: 80px;
    height: 80px;
    margin-left: 50px;
    cursor: pointer;
    -webkit-animation: bounce 3s ease infinite;
            animation: bounce 3s ease infinite;
  }


  .rock {
    position: -webkit-sticky;
    position: sticky;
    bottom: 10%;
    width: 15%;
    margin-left: 30px;
  }

  .stickyimg {
    position: -webkit-sticky;
    position: sticky;
    bottom: 80px;
    width: 30%;
    margin-left: 70%;
    justify-content: flex-end;
    -webkit-animation: floating 2.5s ease-in-out infinite;
            animation: floating 2.5s ease-in-out infinite;
  }

  .stickyGuitar {
    /* Make the image sticky at the bottom */
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 100%;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .priceBox {
    background-color: #00000075;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 25px;
    border-radius: 20px;
    border: 2px dashed #eb2f0e;
  }

  .quotes {
    color: #ebc95a;
    font-size: 22px;
    font-family: 'Caveat', cursive;

  }

  .prices {
    text-align: center;
    color: #b4e3ff;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pricesN {
    text-align: center;
    color: rgba(207, 207, 207, 0.61);
    font-style: italic;
    font-size: 18px;
  }

  .poly {
    width: 25px;
    height: 25px;
    margin-left: 10px;

  }

  .note {
    text-align: center;
    color: red;
    font-size: 20px;
  }

  .mainImg {
    width: 55%;
    height: 55%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 14px;
    font-family: 'Catamaran', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 157, 157);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 35px;
    margin-right: 35px;
  }

  .connectBtn {
    background-color: #beb41f00;
    color: rgb(255, 255, 255);
    padding: 10px;
    font-size: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 5px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #3c9af3);
    border-image-slice: 1;
    display: flex;
  }

  .connectBtn:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: rgb(0, 0, 0);
    background: linear-gradient(315deg, rgba(0, 0, 0, 1) 0%, rgba(41, 1, 4, 1) 50%, rgba(0, 0, 0, 1) 59%, rgba(56, 0, 4, 1) 100%);
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/logo.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-bottom: 7vh;
    margin-top: 5vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 7rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #ee1d1d, #fd3b3b);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 7rem;
    line-height: 7rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.3rem;
    font-size: 30px;
    color: #fdba00;
    letter-spacing: 2px;
    font-family: 'Orbitron', sans-serif;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 10rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 10rem;
    line-height: 10rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    padding-top: 15%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/fire.b98ac362.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
    background-size: cover;
  }

  .boxWrap2Mint {

    background: rgb(61, 3, 3);
    background: radial-gradient(circle, rgba(61, 3, 3, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(13, 0, 2, 1) 100%);
    background-size: cover;
    padding-bottom: 7%;
    padding-top: 7%;

  }

  .boxWrapNFT {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #000000;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 10%;
    padding-bottom: 7%;
    background: rgb(89, 4, 35);
    background: radial-gradient(circle, rgba(89, 4, 35, 0.5178243172268908) 0%, rgba(222, 6, 86, 0.15367865896358546) 50%, rgba(0, 0, 0, 0) 100%);
  }


  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  iframe {
    height: 70vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    margin-top: 30px;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 100px;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 20px;
    border: 2px solid transparent;
    -webkit-animation: borderGlow 2s infinite;
            animation: borderGlow 2s infinite;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #ff5c5c);
    border-image-slice: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 142, 236);
    background: linear-gradient(117deg, rgba(0, 142, 236, 0.09485512955182074) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(24, 4, 7, 1) 100%);
  }

  .about2-2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  @-webkit-keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  .conT {
    font-size: 60px;
    color: rgb(255, 255, 255);
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT2 {
    font-size: 60px;
    color: white;
    text-align: center;
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .listContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .listContainer2 {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .listMain {
    margin-bottom: 8%;
    margin-left: auto;
    justify-content: center;
    width: 90%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: -5px 5px black;
    border: 1px solid black;
    padding: 20px;
    transition: box-shadow 0.3s ease;
  }

  .listMain:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .listH {
    font-size: 23px;
    color: #ffd415;
    letter-spacing: 2px;
    text-align: center;
    text-shadow: 0 0 10px #e6414198;
  }

  .listCon {
    font-size: 18px;
    color: rgb(253, 245, 175);

    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .in1 {
    font-family: 'Orbitron', sans-serif;
    text-align: center;
    font-size: 40px;
    color: #ffffff;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff9c;
  }

  .in2 {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    margin-bottom: 6%;
    font-family: 'Caveat', cursive;
    line-height: 33px;

  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #002bff,
        #7a00ff,
        #ff00c8,
        #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .introduction2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .hl {
    font-family: 'Orbitron', sans-serif;
    color: #ffffff;
  }

  .download {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
  }

  .explore {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 30px;
    border: none;
    background-color: #0084ff;
    box-shadow: -3px 3px #01519c;
    color: white;
    border-radius: 100px;
    padding: 10px;
  }

  .explore:hover {
    -webkit-animation: shake 0.3s ease-in-out;
            animation: shake 0.3s ease-in-out;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }


  .storyCon {
    font-size: 16px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
  }

  .nameT {
    font-size: 22px;
    color: rgb(250, 48, 48);
    text-align: center;
    margin-top: 20px;
  }

  .positionT {
    font-size: 15px;
    color: rgb(255, 255, 255);
    text-align: center;
    letter-spacing: 1px;
  }

  .teamMain {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .teamMain img {
    width: 250px;
    border-radius: 100%;
    border: 2px #964835 solid;
    border: 2px #964835 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  @-webkit-keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }

  @keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }


  .stripe {
    width: 100%;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 20px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 1px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .type {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 5%;
  }

  .bgC {
    background-color: #141414;
    border-radius: 50px;
    padding: 30px;
    border: 1px solid white;
    box-shadow: rgba(243, 230, 224, 0.5) 0 3px 8px;
  }

  .typeCon {
    color: rgb(253, 175, 175);

    font-size: 18px;
    width: 100%;
    margin-bottom: 20px;
  }

  .imagesNormal {
    justify-content: space-evenly;
    width: 100%;
  }

  .imagesNormalDiv {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
  }

  .imagesNormal img {
    width: 170px;
    height: 170px;
    margin-bottom: 20px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .imagesNormal img:hover {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    margin-top: 2%;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border: 2px solid white;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 60%;
  }

  .selectedImg2 {
    background-image: url('https://gif-4qi.pages.dev/images/gif.gif');
    background-size: contain;
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    z-index: 10000000;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .price {
    text-align: center;
    font-size: 22px;
    color: rgb(255, 255, 255);
    z-index: 10000000;

    letter-spacing: 2px;
    cursor: pointer;
  }

  .price:hover {
    color: #0084ff;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #ffee0000;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: #ffffff 2px solid;
    background-color: #ffee0000;
    transition: box-shadow 0.3s ease;
  }

  .btnfos-0-2:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }


  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    background-color: #ffee0000;
    border: 1px solid #ffffff;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: box-shadow 0.3s ease;
    display: none;
  }

  .btnfos-0-mob {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    background-color: #ffee0000;
    border: 1px solid #ffffff;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: box-shadow 0.3s ease;
  }


  /* CSS */
  .button-42 {
    background-color: transparent;
    background-color: initial;
    background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
    height: 40px;
    line-height: 40px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
    transition: box-shadow .2s;
  }

  .button-42:hover {
    box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
  }

  .btnfos-0-3:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  #dogSelect1 {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    background-color: #000000ab;
    color: white;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    cursor: pointer;
  }

  #dogSelect {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    background-color: #000000ab;
    color: white;

    width: 45%;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 300px;
    /* Set a maximum height for the container */
    -webkit-animation-name: pop;
            animation-name: pop;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  @-webkit-keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .down {
    width: 20px;
    height: 20px;
    margin-left: 10px;

  }

  #dogSelect img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  #dogSelect div {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;

    margin-top: auto;
    margin-bottom: auto;
  }

  .textH:hover {
    color: #009ab8;
    cursor: pointer;
  }

  .selectImg {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .selectImg:hover {
    background-color: rgba(128, 128, 128, 0.644);
  }

  select {}


  .nftamount {
    color: #ffffff;
    font-size: 100px;
    font-family: 'Catamaran', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #17ff1e;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Catamaran', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 22px;
    color: #ffffff;
    text-align: center;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 60px;
    height: 60px;
    margin-left: 20px;
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 220px;
    height: 220px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }


  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 164, 164);
    font-size: 28px;
    font-family: 'Caveat', cursive;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Catamaran', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    font-family: 'Catamaran', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 20px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  table {
    border-collapse: collapse;
    width: 90%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .tableMain {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  table img {
    width: 100px;
    height: 100px;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #4d4d4d86;
    color: #ffffff;

    font-size: 18px;
    position: relative;
  }

  tr:nth-child(even) {
    background-color: #4d4d4d86;
  }

  th {
    background-color: #202020;
    color: #2491f7;
    font-size: 20px;
  }

  /* Show the hover images when hovering over the table cells */
  .hoverDiv img {
    width: 200px;
    height: 200px;
  }

  .hoverDiv {
    display: flex;
    justify-content: space-evenly;
  }

  .sticky {
    width: 20%;
  }

  .stickyDiv {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;

  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .connect2-2-Mob {
    display: none;
  }

  .btnfos-0-3-mob {
    display: none;
  }

  .details {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    background-color: #252525bb;
    padding: 15px;
    border-radius: 10px;
  }

  .typeH {
    font-size: 22px;
  }

  .musicOn {
    position: -webkit-sticky;
    position: sticky;
    bottom: 80px;
    width: 80px;
    height: 80px;
    margin-left: 50px;
    cursor: pointer;
    -webkit-animation: bounce 3s ease infinite;
            animation: bounce 3s ease infinite;
  }


  .rock {
    position: -webkit-sticky;
    position: sticky;
    bottom: 10%;
    width: 15%;
    margin-left: 30px;
  }

  .stickyimg {
    position: -webkit-sticky;
    position: sticky;
    bottom: 80px;
    width: 30%;
    margin-left: 70%;
    justify-content: flex-end;
    -webkit-animation: floating 2.5s ease-in-out infinite;
            animation: floating 2.5s ease-in-out infinite;
  }

  .stickyGuitar {
    /* Make the image sticky at the bottom */
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 100%;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .priceBox {
    background-color: #00000075;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 25px;
    border-radius: 20px;
    border: 2px dashed #eb2f0e;
  }

  .quotes {
    color: #ebc95a;
    font-size: 22px;
    font-family: 'Caveat', cursive;

  }

  .prices {
    text-align: center;
    color: #b4e3ff;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pricesN {
    text-align: center;
    color: rgba(207, 207, 207, 0.61);
    font-style: italic;
    font-size: 18px;
  }

  .poly {
    width: 25px;
    height: 25px;
    margin-left: 10px;

  }

  .note {
    text-align: center;
    color: red;
    font-size: 20px;
  }

  .mainImg {
    width: 40%;
    height: 40%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    z-index: 1000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 14px;
    font-family: 'Catamaran', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 157, 157);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 35px;
    margin-right: 35px;
  }

  .connectBtn {
    background-color: #beb41f00;
    color: rgb(255, 255, 255);
    padding: 10px;
    font-size: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 5px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #3c9af3);
    border-image-slice: 1;
    display: flex;
  }

  .connectBtn:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: rgb(0, 0, 0);
    background: linear-gradient(315deg, rgba(0, 0, 0, 1) 0%, rgba(41, 1, 4, 1) 50%, rgba(0, 0, 0, 1) 59%, rgba(56, 0, 4, 1) 100%);
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/logo.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-bottom: 10vh;
    margin-top: 5vh;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 7rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #ee1d1d, #fd3b3b);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 7rem;
    line-height: 7rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.3rem;
    font-size: 30px;
    color: #fdba00;
    letter-spacing: 2px;
    font-family: 'Orbitron', sans-serif;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 10rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 10rem;
    line-height: 10rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    padding-top: 15%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/fire.b98ac362.jpg) no-repeat center center;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
    background-size: cover;
  }

  .boxWrap2Mint {

    background: rgb(61, 3, 3);
    background: radial-gradient(circle, rgba(61, 3, 3, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(13, 0, 2, 1) 100%);
    background-size: cover;
    padding-bottom: 7%;
    padding-top: 7%;

  }

  .boxWrapNFT {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #000000;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 10%;
    padding-bottom: 7%;
    background: rgb(89, 4, 35);
    background: radial-gradient(circle, rgba(89, 4, 35, 0.5178243172268908) 0%, rgba(222, 6, 86, 0.15367865896358546) 50%, rgba(0, 0, 0, 0) 100%);
  }


  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  iframe {
    height: 70vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    margin-top: 30px;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 100px;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 20px;
    border: 2px solid transparent;
    -webkit-animation: borderGlow 2s infinite;
            animation: borderGlow 2s infinite;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #ff5c5c);
    border-image-slice: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 142, 236);
    background: linear-gradient(117deg, rgba(0, 142, 236, 0.09485512955182074) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(24, 4, 7, 1) 100%);
  }

  .about2-2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  @-webkit-keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  .conT {
    font-size: 60px;
    color: rgb(255, 255, 255);
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT2 {
    font-size: 60px;
    color: white;
    text-align: center;
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .listContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .listContainer2 {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .listMain {
    margin-bottom: 8%;
    margin-left: auto;
    justify-content: center;
    width: 90%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: -5px 5px black;
    border: 1px solid black;
    padding: 20px;
    transition: box-shadow 0.3s ease;
  }

  .listMain:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .listH {
    font-size: 23px;
    color: #ffd415;
    letter-spacing: 2px;
    text-align: center;
    text-shadow: 0 0 10px #e6414198;
  }

  .listCon {
    font-size: 18px;
    color: rgb(253, 245, 175);

    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .in1 {
    font-family: 'Orbitron', sans-serif;
    text-align: left;
    font-size: 40px;
    color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff9c;
  }

  .in2 {
    text-align: left;
    font-size: 25px;
    color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    margin-bottom: 6%;
    font-family: 'Caveat', cursive;
    line-height: 33px;

  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #002bff,
        #7a00ff,
        #ff00c8,
        #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .introduction2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .hl {
    font-family: 'Orbitron', sans-serif;
    color: #ffffff;
  }

  .download {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
  }

  .explore {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 30px;
    border: none;
    background-color: #0084ff;
    box-shadow: -3px 3px #01519c;
    color: white;
    border-radius: 100px;
    padding: 10px;
  }

  .explore:hover {
    -webkit-animation: shake 0.3s ease-in-out;
            animation: shake 0.3s ease-in-out;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }


  .storyCon {
    font-size: 16px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
  }

  .nameT {
    font-size: 22px;
    color: rgb(250, 48, 48);
    text-align: center;
    margin-top: 20px;
  }

  .positionT {
    font-size: 15px;
    color: rgb(255, 255, 255);
    text-align: center;
    letter-spacing: 1px;
  }

  .teamMain {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .teamMain img {
    width: 250px;
    border-radius: 100%;
    border: 2px #964835 solid;
    border: 2px #964835 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  @-webkit-keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }

  @keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }


  .stripe {
    width: 100%;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 20px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 1px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .type {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 5%;
  }

  .bgC {
    background-color: #141414;
    border-radius: 50px;
    padding: 30px;
    border: 1px solid white;
    box-shadow: rgba(243, 230, 224, 0.5) 0 3px 8px;
  }

  .typeCon {
    color: rgb(253, 175, 175);

    font-size: 18px;
    width: 100%;
    margin-bottom: 20px;
  }

  .imagesNormal {
    justify-content: space-evenly;
    width: 100%;
  }

  .imagesNormalDiv {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
  }

  .imagesNormal img {
    width: 170px;
    height: 170px;
    margin-bottom: 20px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .imagesNormal img:hover {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    margin-top: 2%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border: 2px solid white;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 60%;
  }

  .selectedImg2 {
    background-image: url('https://gif-4qi.pages.dev/images/gif.gif');
    background-size: contain;
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    z-index: 10000000;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .price {
    text-align: center;
    font-size: 22px;
    color: rgb(255, 255, 255);
    z-index: 10000000;

    letter-spacing: 2px;
    cursor: pointer;
  }

  .price:hover {
    color: #0084ff;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #ffee0000;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: #ffffff 2px solid;
    background-color: #ffee0000;
    transition: box-shadow 0.3s ease;
  }

  .btnfos-0-2:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }


  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    background-color: #ffee0000;
    border: 1px solid #ffffff;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: box-shadow 0.3s ease;
  }


  /* CSS */
  .button-42 {
    background-color: transparent;
    background-color: initial;
    background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
    height: 40px;
    line-height: 40px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
    transition: box-shadow .2s;
  }

  .button-42:hover {
    box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
  }

  .btnfos-0-3:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  #dogSelect1 {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    background-color: #000000ab;
    color: white;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    cursor: pointer;
  }

  #dogSelect {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    background-color: #000000ab;
    color: white;

    width: 45%;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 300px;
    /* Set a maximum height for the container */
    -webkit-animation-name: pop;
            animation-name: pop;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  @-webkit-keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .down {
    width: 20px;
    height: 20px;
    margin-left: 10px;

  }

  #dogSelect img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  #dogSelect div {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;

    margin-top: auto;
    margin-bottom: auto;
  }

  .textH:hover {
    color: #009ab8;
    cursor: pointer;
  }

  .selectImg {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .selectImg:hover {
    background-color: rgba(128, 128, 128, 0.644);
  }

  select {}


  .nftamount {
    color: #ffffff;
    font-size: 100px;
    font-family: 'Catamaran', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #17ff1e;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Catamaran', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 22px;
    color: #ffffff;
    text-align: center;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 60px;
    height: 60px;
    margin-left: 20px;
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 220px;
    height: 220px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }


  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 164, 164);
    font-size: 28px;
    font-family: 'Caveat', cursive;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Catamaran', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    font-family: 'Catamaran', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 20px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  table {
    border-collapse: collapse;
    width: 90%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .tableMain {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  table img {
    width: 100px;
    height: 100px;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #4d4d4d86;
    color: #ffffff;

    font-size: 18px;
    position: relative;
  }

  tr:nth-child(even) {
    background-color: #4d4d4d86;
  }

  th {
    background-color: #202020;
    color: #2491f7;
    font-size: 20px;
  }

  /* Show the hover images when hovering over the table cells */
  .hoverDiv img {
    width: 200px;
    height: 200px;
  }

  .hoverDiv {
    display: flex;
    justify-content: space-evenly;
  }

  .sticky {
    width: 20%;
  }

  .stickyDiv {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;

  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .connect2-2-Mob {
    display: none;
  }

  .btnfos-0-3-mob {
    display: none;
  }

  .details {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    background-color: #252525bb;
    padding: 15px;
    border-radius: 10px;
  }


  .musicOn {
    position: -webkit-sticky;
    position: sticky;
    bottom: 120px;
    width: 80px;
    height: 80px;
    margin-left: 50px;
    cursor: pointer;
    -webkit-animation: bounce 3s ease infinite;
            animation: bounce 3s ease infinite;
  }


  .rock {
    position: -webkit-sticky;
    position: sticky;
    bottom: 10%;
    width: 15%;
    margin-left: 30px;
  }

  .stickyimg {
    position: -webkit-sticky;
    position: sticky;
    bottom: 80px;
    width: 30%;
    margin-left: 70%;
    justify-content: flex-end;
    -webkit-animation: floating 2.5s ease-in-out infinite;
            animation: floating 2.5s ease-in-out infinite;
  }

  .stickyGuitar {
    /* Make the image sticky at the bottom */
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 100%;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .priceBox {
    background-color: #00000075;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 25px;
    border-radius: 20px;
    border: 2px dashed #eb2f0e;
  }

  .quotes {
    color: #ebc95a;
    font-size: 28px;
    font-family: 'Caveat', cursive;

  }

  .prices {
    text-align: center;
    color: #b4e3ff;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pricesN {
    text-align: center;
    color: rgba(207, 207, 207, 0.61);
    font-style: italic;
    font-size: 18px;
  }

  .poly {
    width: 25px;
    height: 25px;
    margin-left: 10px;

  }

  .note {
    text-align: center;
    color: red;
    font-size: 20px;
  }

  .mainImg {
    width: 40%;
    height: 40%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    z-index: 1000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 14px;
    font-family: 'Catamaran', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 157, 157);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 35px;
    margin-right: 35px;
  }

  .connectBtn {
    background-color: #beb41f00;
    color: rgb(255, 255, 255);
    padding: 10px;
    font-size: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 5px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #3c9af3);
    border-image-slice: 1;
    display: flex;
  }

  .connectBtn:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: rgb(0, 0, 0);
    background: linear-gradient(315deg, rgba(0, 0, 0, 1) 0%, rgba(41, 1, 4, 1) 50%, rgba(0, 0, 0, 1) 59%, rgba(56, 0, 4, 1) 100%);
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/logo.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-bottom: 15vh;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #ee1d1d, #fd3b3b);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 35px;
    color: #fdba00;
    letter-spacing: 2px;
    font-family: 'Orbitron', sans-serif;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 10rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 10rem;
    line-height: 10rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    padding-top: 15%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/fire.b98ac362.jpg) no-repeat center center;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
    background-size: cover;
  }

  .boxWrap2Mint {

    background: rgb(61, 3, 3);
    background: radial-gradient(circle, rgba(61, 3, 3, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(13, 0, 2, 1) 100%);
    background-size: cover;
    padding-bottom: 7%;
    padding-top: 7%;

  }

  .boxWrapNFT {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #000000;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 10%;
    padding-bottom: 7%;
    background: rgb(89, 4, 35);
    background: radial-gradient(circle, rgba(89, 4, 35, 0.5178243172268908) 0%, rgba(222, 6, 86, 0.15367865896358546) 50%, rgba(0, 0, 0, 0) 100%);
  }


  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  iframe {
    height: 70vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    margin-top: 30px;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 100px;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 20px;
    border: 2px solid transparent;
    -webkit-animation: borderGlow 2s infinite;
            animation: borderGlow 2s infinite;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #ff5c5c);
    border-image-slice: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 142, 236);
    background: linear-gradient(117deg, rgba(0, 142, 236, 0.09485512955182074) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(24, 4, 7, 1) 100%);
  }

  .about2-2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  @-webkit-keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  .conT {
    font-size: 80px;
    color: rgb(255, 255, 255);
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT2 {
    font-size: 80px;
    color: white;
    text-align: center;
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .listContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .listContainer2 {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .listMain {
    margin-bottom: 8%;
    margin-left: auto;
    justify-content: center;
    width: 90%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: -5px 5px black;
    border: 1px solid black;
    padding: 20px;
    transition: box-shadow 0.3s ease;
  }

  .listMain:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .listH {
    font-size: 23px;
    color: #ffd415;
    letter-spacing: 2px;
    text-align: center;
    text-shadow: 0 0 10px #e6414198;
  }

  .listCon {
    font-size: 18px;
    color: rgb(253, 245, 175);

    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .in1 {
    font-family: 'Orbitron', sans-serif;
    text-align: left;
    font-size: 50px;
    color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff9c;
  }

  .in2 {
    text-align: left;
    font-size: 32px;
    color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 5%;
    font-family: 'Caveat', cursive;
    line-height: 33px;

  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 22px;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #002bff,
        #7a00ff,
        #ff00c8,
        #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .introduction2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .hl {
    font-family: 'Orbitron', sans-serif;
    color: #ffffff;
  }

  .download {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
  }

  .explore {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 30px;
    border: none;
    background-color: #0084ff;
    box-shadow: -3px 3px #01519c;
    color: white;
    border-radius: 100px;
    padding: 10px;
  }

  .explore:hover {
    -webkit-animation: shake 0.3s ease-in-out;
            animation: shake 0.3s ease-in-out;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }


  .storyCon {
    font-size: 18px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
  }

  .nameT {
    font-size: 25px;
    color: rgb(250, 48, 48);
    text-align: center;
    margin-top: 20px;
  }

  .positionT {

    color: rgb(255, 255, 255);
    text-align: center;
    letter-spacing: 1px;
  }

  .teamMain {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .teamMain img {
    width: 300px;
    border-radius: 100%;
    border: 2px #964835 solid;
    border: 2px #964835 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  @-webkit-keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }

  @keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }


  .stripe {
    width: 100%;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 20px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 1px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .type {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 5%;
  }

  .bgC {
    background-color: #141414;
    border-radius: 50px;
    padding: 30px;
    border: 1px solid white;
    box-shadow: rgba(243, 230, 224, 0.5) 0 3px 8px;
  }

  .typeCon {
    color: rgb(253, 175, 175);

    font-size: 20px;
    width: 80%;
    margin-bottom: 20px;
  }

  .imagesNormal {
    justify-content: space-evenly;
    width: 100%;
  }

  .imagesNormalDiv {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
  }

  .imagesNormal img {
    width: 180px;
    height: 180px;
    margin-bottom: 20px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .imagesNormal img:hover {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    margin-top: 2%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border: 2px solid white;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 60%;
  }

  .selectedImg2 {
    background-image: url('https://gif-4qi.pages.dev/images/gif.gif');
    background-size: contain;
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    z-index: 10000000;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: rgb(255, 255, 255);
    z-index: 10000000;

    letter-spacing: 2px;
    cursor: pointer;
  }

  .price:hover {
    color: #0084ff;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #ffee0000;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: #ffffff 2px solid;
    background-color: #ffee0000;
    transition: box-shadow 0.3s ease;
  }

  .btnfos-0-2:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }


  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    background-color: #ffee0000;
    border: 1px solid #ffffff;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: box-shadow 0.3s ease;
  }


  /* CSS */
  .button-42 {
    background-color: transparent;
    background-color: initial;
    background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
    height: 40px;
    line-height: 40px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
    transition: box-shadow .2s;
  }

  .button-42:hover {
    box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
  }

  .btnfos-0-3:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  #dogSelect1 {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    background-color: #000000ab;
    color: white;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    cursor: pointer;
  }

  #dogSelect {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    background-color: #000000ab;
    color: white;

    width: 45%;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 300px;
    /* Set a maximum height for the container */
    -webkit-animation-name: pop;
            animation-name: pop;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  @-webkit-keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .down {
    width: 20px;
    height: 20px;
    margin-left: 10px;

  }

  #dogSelect img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  #dogSelect div {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;

    margin-top: auto;
    margin-bottom: auto;
  }

  .textH:hover {
    color: #009ab8;
    cursor: pointer;
  }

  .selectImg {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .selectImg:hover {
    background-color: rgba(128, 128, 128, 0.644);
  }

  select {}


  .nftamount {
    color: #ffffff;
    font-size: 100px;
    font-family: 'Catamaran', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #17ff1e;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Catamaran', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 25px;
    color: #ffffff;
    text-align: center;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 250px;
    height: 250px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }


  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 164, 164);
    font-size: 32px;
    font-family: 'Caveat', cursive;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Catamaran', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    font-family: 'Catamaran', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 20px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  table {
    border-collapse: collapse;
    width: 90%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .tableMain {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  table img {
    width: 100px;
    height: 100px;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #4d4d4d86;
    color: #ffffff;

    font-size: 18px;
    position: relative;
  }

  tr:nth-child(even) {
    background-color: #4d4d4d86;
  }

  th {
    background-color: #202020;
    color: #2491f7;
    font-size: 20px;
  }

  /* Show the hover images when hovering over the table cells */
  .hoverDiv img {
    width: 200px;
    height: 200px;
  }

  .hoverDiv {
    display: flex;
    justify-content: space-evenly;
  }

  .sticky {
    width: 20%;
  }

  .stickyDiv {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;

  }
}

@media screen and (min-width: 1920px) {
  .connect2-2-Mob {
    display: none;
  }

  .btnfos-0-3-mob {
    display: none;
  }

  .details {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    background-color: #252525bb;
    padding: 15px;
    border-radius: 10px;
  }

  .musicOn {
    position: -webkit-sticky;
    position: sticky;
    bottom: 140px;
    width: 110px;
    height: 110px;
    margin-left: 60px;
    cursor: pointer;
    -webkit-animation: bounce 3s ease infinite;
            animation: bounce 3s ease infinite;
  }


  .rock {
    position: -webkit-sticky;
    position: sticky;
    bottom: 10%;
    width: 15%;
    margin-left: 30px;
  }

  .stickyimg {
    position: -webkit-sticky;
    position: sticky;
    bottom: 80px;
    width: 30%;
    margin-left: 70%;
    justify-content: flex-end;
    -webkit-animation: floating 2.5s ease-in-out infinite;
            animation: floating 2.5s ease-in-out infinite;
  }

  .stickyGuitar {
    /* Make the image sticky at the bottom */
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 100%;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .priceBox {
    background-color: #00000075;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 25px;
    border-radius: 20px;
    border: 3px dashed #eb2f0e;
  }

  .quotes {
    color: #ebc95a;
    font-size: 38px;
    font-family: 'Caveat', cursive;

  }

  .prices {
    text-align: center;
    color: #b4e3ff;
    font-size: 27px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .pricesN {
    text-align: center;
    color: rgba(207, 207, 207, 0.61);
    font-style: italic;
    font-size: 24px;
  }

  .poly {
    width: 25px;
    height: 25px;
    margin-left: 10px;

  }

  .note {
    text-align: center;
    color: red;
    font-size: 20px;
  }

  .mainImg {
    width: 40%;
    height: 40%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 22px;
    font-family: 'Catamaran', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 157, 157);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: #beb41f00;
    color: rgb(255, 255, 255);
    padding: 15px;
    font-size: 20px;
    padding-left: 45px;
    padding-right: 45px;
    border-width: 2px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 10px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #3c9af3);
    border-image-slice: 1;
    display: flex;
  }

  .connectBtn:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: rgb(0, 0, 0);
    background: linear-gradient(315deg, rgba(0, 0, 0, 1) 0%, rgba(41, 1, 4, 1) 50%, rgba(0, 0, 0, 1) 59%, rgba(56, 0, 4, 1) 100%);
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/logo.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-bottom: 15vh;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    width: 150px;
    height: 150px;
    border-radius: 100px;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 10rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 5px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #ee1d1d, #fd3b3b);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 10rem;
    line-height: 10rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 50px;
    color: #fdba00;
    letter-spacing: 4px;
    font-family: 'Orbitron', sans-serif;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 10rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 10rem;
    line-height: 10rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    padding-top: 15%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/fire.b98ac362.jpg) no-repeat center center;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
    background-size: cover;
  }

  .boxWrap2Mint {

    background: rgb(61, 3, 3);
    background: radial-gradient(circle, rgba(61, 3, 3, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(13, 0, 2, 1) 100%);
    background-size: cover;
    padding-bottom: 7%;
    padding-top: 7%;

  }

  .boxWrapNFT {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #000000;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 10%;
    padding-bottom: 7%;
    background: rgb(89, 4, 35);
    background: radial-gradient(circle, rgba(89, 4, 35, 0.5178243172268908) 0%, rgba(222, 6, 86, 0.15367865896358546) 50%, rgba(0, 0, 0, 0) 100%);
  }


  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  iframe {
    height: 70vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    margin-top: 30px;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 100px;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 25px;
    border: 2px solid transparent;
    -webkit-animation: borderGlow 2s infinite;
            animation: borderGlow 2s infinite;
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #ff5c5c);
    border-image-slice: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 142, 236);
    background: linear-gradient(117deg, rgba(0, 142, 236, 0.09485512955182074) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(24, 4, 7, 1) 100%);
  }

  .about2-2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  @-webkit-keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  .conT {
    font-size: 110px;
    color: rgb(255, 255, 255);
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT2 {
    font-size: 110px;
    color: white;
    text-align: center;
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .listContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .listContainer2 {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .listMain {
    margin-bottom: 8%;
    margin-left: auto;
    justify-content: center;
    width: 90%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: -5px 5px black;
    border: 1px solid black;
    padding: 20px;
    transition: box-shadow 0.3s ease;
  }

  .listMain:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .listH {
    font-size: 23px;
    color: #ffd415;
    letter-spacing: 2px;
    text-align: center;
    text-shadow: 0 0 10px #e6414198;
  }

  .listCon {
    font-size: 18px;
    color: rgb(253, 245, 175);

    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .in1 {
    font-family: 'Orbitron', sans-serif;
    text-align: left;
    font-size: 75px;
    color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff9c;
  }

  .in2 {
    text-align: left;
    font-size: 48px;
    color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 5%;
    font-family: 'Caveat', cursive;
    line-height: 52px;

  }

  .button-85 {
    padding: 0.8em 2.8em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 28px;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #002bff,
        #7a00ff,
        #ff00c8,
        #ff0000);
    position: absolute;
    top: -4px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .introduction2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .hl {
    font-family: 'Orbitron', sans-serif;
    color: #ffffff;
  }

  .download {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
  }

  .explore {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 30px;
    border: none;
    background-color: #0084ff;
    box-shadow: -3px 3px #01519c;
    color: white;
    border-radius: 100px;
    padding: 10px;
  }

  .explore:hover {
    -webkit-animation: shake 0.3s ease-in-out;
            animation: shake 0.3s ease-in-out;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }


  .storyCon {
    font-size: 26px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
  }

  .nameT {
    font-size: 35px;
    color: rgb(250, 48, 48);
    text-align: center;
    margin-top: 30px;
  }

  .positionT {

    color: rgb(255, 255, 255);
    text-align: center;
    letter-spacing: 1px;
    font-size: 22px;
  }

  .teamMain {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .teamMain img {
    width: 400px;
    border-radius: 100%;
    border: 2px #964835 solid;
    border: 2px #964835 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  @-webkit-keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }

  @keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }


  .stripe {
    width: 100%;
  }

  .storyCon p {
    color: white;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 20px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 1px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .type {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 5%;
  }

  .bgC {
    background-color: #141414;
    border-radius: 50px;
    padding: 30px;
    border: 1px solid white;
    box-shadow: rgba(243, 230, 224, 0.5) 0 3px 8px;
  }

  .typeCon {
    color: rgb(253, 175, 175);
    font-size: 28px;
    width: 80%;
    margin-bottom: 40px;
  }

  .typeH {
    font-size: 36px;
  }

  .imagesNormal {
    justify-content: space-evenly;
    width: 100%;
  }

  .imagesNormalDiv {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
  }

  .imagesNormal img {
    width: 220px;
    height: 220px;
    margin-bottom: 20px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .imagesNormal img:hover {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    margin-top: 2%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border: 3px solid white;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 60%;
  }

  .selectedImg2 {
    background-image: url('https://gif-4qi.pages.dev/images/gif.gif');
    background-size: contain;
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    z-index: 10000000;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .price {
    text-align: center;
    font-size: 34px;
    color: rgb(255, 255, 255);
    z-index: 10000000;

    letter-spacing: 2px;
    cursor: pointer;
  }

  .price:hover {
    color: #0084ff;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 70px;
    margin-right: 70px;
    width: 100px;
    height: 100px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #ffee0000;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: #ffffff 3px solid;
    background-color: #ffee0000;
    transition: box-shadow 0.3s ease;
  }

  .btnfos-0-2:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }


  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 12px;
    font-size: 40px;
    width: 430px;
    background-color: #ffee0000;
    border: 2.5px solid #ffffff;
    color: rgb(255, 255, 255);
    letter-spacing: 4px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: box-shadow 0.3s ease;
  }


  /* CSS */
  .button-42 {
    background-color: transparent;
    background-color: initial;
    background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
    height: 40px;
    line-height: 40px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
    transition: box-shadow .2s;
  }

  .button-42:hover {
    box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
  }

  .btnfos-0-3:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  #dogSelect1 {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 15px;
    font-size: 24px;
    background-color: #000000ab;
    color: white;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    cursor: pointer;
  }

  #dogSelect {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px;
    font-size: 16px;
    background-color: #000000ab;
    color: white;

    width: 45%;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 300px;
    /* Set a maximum height for the container */
    -webkit-animation-name: pop;
            animation-name: pop;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  @-webkit-keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .down {
    width: 20px;
    height: 20px;
    margin-left: 10px;

  }

  #dogSelect img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  #dogSelect div {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;

    margin-top: auto;
    margin-bottom: auto;
  }

  .textH:hover {
    color: #009ab8;
    cursor: pointer;
  }

  .selectImg {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .selectImg:hover {
    background-color: rgba(128, 128, 128, 0.644);
  }

  select {}


  .nftamount {
    color: #ffffff;
    font-size: 145px;
    font-family: 'Catamaran', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #17ff1e;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Catamaran', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 36px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 15px;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 90px;
    height: 90px;
    margin-left: 25px;
    margin-right: 25px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 350px;
    height: 350px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 22px;
  }


  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 164, 164);
    font-size: 42px;
    font-family: 'Caveat', cursive;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Catamaran', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    font-family: 'Catamaran', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 20px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  table {
    border-collapse: collapse;
    width: 90%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .tableMain {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  table img {
    width: 100px;
    height: 100px;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #4d4d4d86;
    color: #ffffff;

    font-size: 18px;
    position: relative;
  }

  tr:nth-child(even) {
    background-color: #4d4d4d86;
  }

  th {
    background-color: #202020;
    color: #2491f7;
    font-size: 20px;
  }

  /* Show the hover images when hovering over the table cells */
  .hoverDiv img {
    width: 200px;
    height: 200px;
  }

  .hoverDiv {
    display: flex;
    justify-content: space-evenly;
  }

  .sticky {
    width: 20%;
  }

  .stickyDiv {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;

  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .connect2-2-Mob {
    display: none;
  }

  .btnfos-0-3-mob {
    display: none;
  }

  .details {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    background-color: #252525bb;
    padding: 15px;
    border-radius: 10px;
  }

  .musicOn {
    position: -webkit-sticky;
    position: sticky;
    bottom: 180px;
    width: 150px;
    height: 150px;
    margin-left: 80px;
    cursor: pointer;
    -webkit-animation: bounce 3s ease infinite;
            animation: bounce 3s ease infinite;
  }


  .rock {
    position: -webkit-sticky;
    position: sticky;
    bottom: 10%;
    width: 15%;
    margin-left: 30px;
  }

  .stickyimg {
    position: -webkit-sticky;
    position: sticky;
    bottom: 80px;
    width: 30%;
    margin-left: 70%;
    justify-content: flex-end;
    -webkit-animation: floating 2.5s ease-in-out infinite;
            animation: floating 2.5s ease-in-out infinite;
  }

  .stickyGuitar {
    /* Make the image sticky at the bottom */
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 100%;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .priceBox {
    background-color: #00000075;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 30px;
    border-radius: 40px;
    border: 4px dashed #eb2f0e;
  }

  .quotes {
    color: #ebc95a;
    font-size: 50px;
    font-family: 'Caveat', cursive;

  }

  .prices {
    text-align: center;
    color: #b4e3ff;
    font-size: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .pricesN {
    text-align: center;
    color: rgba(207, 207, 207, 0.61);
    font-style: italic;
    font-size: 24px;
  }

  .poly {
    width: 25px;
    height: 25px;
    margin-left: 10px;

  }

  .note {
    text-align: center;
    color: red;
    font-size: 20px;
  }

  .mainImg {
    width: 40%;
    height: 40%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 28px;
    font-family: 'Catamaran', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 157, 157);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 65px;
    margin-right: 65px;
  }

  .connectBtn {
    background-color: #beb41f00;
    color: rgb(255, 255, 255);
    padding: 20px;
    font-size: 28px;
    padding-left: 60px;
    padding-right: 60px;
    border-width: 3px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 10px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #3c9af3);
    border-image-slice: 1;
    display: flex;
  }

  .connectBtn:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: rgb(0, 0, 0);
    background: linear-gradient(315deg, rgba(0, 0, 0, 1) 0%, rgba(41, 1, 4, 1) 50%, rgba(0, 0, 0, 1) 59%, rgba(56, 0, 4, 1) 100%);
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/logo.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-bottom: 15vh;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    width: 200px;
    height: 200px;
    border-radius: 100px;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 15rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 8px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #ee1d1d, #fd3b3b);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 15rem;
    line-height: 15rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3rem;
    font-size: 70px;
    color: #fdba00;
    letter-spacing: 4px;
    font-family: 'Orbitron', sans-serif;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 10rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 10rem;
    line-height: 10rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    padding-top: 15%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/fire.b98ac362.jpg) no-repeat center center;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
    background-size: cover;
  }

  .boxWrap2Mint {

    background: rgb(61, 3, 3);
    background: radial-gradient(circle, rgba(61, 3, 3, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(13, 0, 2, 1) 100%);
    background-size: cover;
    padding-bottom: 7%;
    padding-top: 7%;

  }

  .boxWrapNFT {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #000000;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 10%;
    padding-bottom: 7%;
    background: rgb(89, 4, 35);
    background: radial-gradient(circle, rgba(89, 4, 35, 0.5178243172268908) 0%, rgba(222, 6, 86, 0.15367865896358546) 50%, rgba(0, 0, 0, 0) 100%);
  }


  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  iframe {
    height: 70vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    margin-top: 30px;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 100px;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 40px;
    border: 2px solid transparent;
    -webkit-animation: borderGlow 2s infinite;
            animation: borderGlow 2s infinite;
    border-width: 7px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #ff5c5c);
    border-image-slice: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 142, 236);
    background: linear-gradient(117deg, rgba(0, 142, 236, 0.09485512955182074) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(24, 4, 7, 1) 100%);
  }

  .about2-2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  @-webkit-keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  .conT {
    font-size: 130px;
    color: rgb(255, 255, 255);
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT2 {
    font-size: 130px;
    color: white;
    text-align: center;
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 10px #ffffff;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .listContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .listContainer2 {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .listMain {
    margin-bottom: 8%;
    margin-left: auto;
    justify-content: center;
    width: 90%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: -5px 5px black;
    border: 1px solid black;
    padding: 20px;
    transition: box-shadow 0.3s ease;
  }

  .listMain:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .listH {
    font-size: 23px;
    color: #ffd415;
    letter-spacing: 2px;
    text-align: center;
    text-shadow: 0 0 10px #e6414198;
  }

  .listCon {
    font-size: 18px;
    color: rgb(253, 245, 175);

    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .in1 {
    font-family: 'Orbitron', sans-serif;
    text-align: left;
    font-size: 95px;
    color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff9c;
  }

  .in2 {
    text-align: left;
    font-size: 61px;
    color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 5%;
    font-family: 'Caveat', cursive;
    line-height: 70px;

  }

  .button-85 {
    padding: 0.8em 2.5em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 38px;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #002bff,
        #7a00ff,
        #ff00c8,
        #ff0000);
    position: absolute;
    top: -5px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .introduction2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .hl {
    font-family: 'Orbitron', sans-serif;
    color: #ffffff;
  }

  .download {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
  }

  .explore {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 30px;
    border: none;
    background-color: #0084ff;
    box-shadow: -3px 3px #01519c;
    color: white;
    border-radius: 100px;
    padding: 10px;
  }

  .explore:hover {
    -webkit-animation: shake 0.3s ease-in-out;
            animation: shake 0.3s ease-in-out;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }


  .storyCon {
    font-size: 34px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
  }

  .nameT {
    font-size: 35px;
    color: rgb(250, 48, 48);
    text-align: center;
    margin-top: 40px;
  }

  .positionT {

    color: rgb(255, 255, 255);
    text-align: center;
    letter-spacing: 1px;
    font-size: 30px;
  }

  .teamMain {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .teamMain img {
    width: 550px;
    border-radius: 100%;
    border: 4px #964835 solid;
    border: 4px #964835 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  @-webkit-keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }

  @keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 15px 5px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 30px 10px #dc0513a4;
    }
  }


  .stripe {
    width: 100%;
  }

  .storyCon p {
    color: white;
    margin-top: 45px;
    margin-bottom: 45px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 20px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 1px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .type {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 5%;
  }

  .bgC {
    background-color: #141414;
    border-radius: 50px;
    padding: 30px;
    border: 1px solid white;
    box-shadow: rgba(243, 230, 224, 0.5) 0 3px 8px;
  }

  .typeCon {
    color: rgb(253, 175, 175);
    font-size: 35px;
    width: 80%;
    margin-bottom: 40px;
  }

  .typeH {
    font-size: 47px;
  }

  .imagesNormal {
    justify-content: space-evenly;
    width: 100%;
  }

  .imagesNormalDiv {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
  }

  .imagesNormal img {
    width: 250px;
    height: 250px;
    margin-bottom: 20px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .imagesNormal img:hover {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    margin-top: 2%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border: 4px solid white;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 60%;
  }

  .selectedImg2 {
    background-image: url('https://gif-4qi.pages.dev/images/gif.gif');
    background-size: contain;
    width: 700px;
    height: 700px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 140px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    z-index: 10000000;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .price {
    text-align: center;
    font-size: 45px;
    color: rgb(255, 255, 255);
    z-index: 10000000;

    letter-spacing: 2px;
    cursor: pointer;
  }

  .price:hover {
    color: #0084ff;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 70px;
    margin-left: 90px;
    margin-right: 90px;
    width: 130px;
    height: 130px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #ffee0000;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: #ffffff 4px solid;
    background-color: #ffee0000;
    transition: box-shadow 0.3s ease;
  }

  .btnfos-0-2:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }


  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 16px;
    font-size: 50px;
    width: 600px;
    background-color: #ffee0000;
    border: 2.5px solid #ffffff;
    color: rgb(255, 255, 255);
    letter-spacing: 4px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: box-shadow 0.3s ease;
  }


  /* CSS */
  .button-42 {
    background-color: transparent;
    background-color: initial;
    background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
    height: 40px;
    line-height: 40px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
    transition: box-shadow .2s;
  }

  .button-42:hover {
    box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
  }

  .btnfos-0-3:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  #dogSelect1 {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    font-size: 32px;
    background-color: #000000ab;
    color: white;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    cursor: pointer;
  }

  #dogSelect {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px;
    font-size: 24px;
    background-color: #000000ab;
    color: white;

    width: 45%;
    margin-left: auto;
    margin-right: auto;
    border: 2px rgba(220, 220, 220, 0.425) solid;
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 500px;
    /* Set a maximum height for the container */
    -webkit-animation-name: pop;
            animation-name: pop;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  @-webkit-keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .down {
    width: 40px;
    height: 40px;
    margin-left: 10px;

  }

  #dogSelect img {
    width: 100px;
    height: 100px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  #dogSelect div {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 28px;

    margin-top: auto;
    margin-bottom: auto;
  }

  .textH:hover {
    color: #009ab8;
    cursor: pointer;
  }

  .selectImg {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .selectImg:hover {
    background-color: rgba(128, 128, 128, 0.644);
  }

  select {}


  .nftamount {
    color: #ffffff;
    font-size: 190px;
    font-family: 'Catamaran', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }


  .success-message {
    color: #17ff1e;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message {
    color: #17ff1e;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 40px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 30px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 37px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 37px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Catamaran', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 45px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 28px;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 130px;
    height: 130px;
    margin-left: 40px;
    margin-right: 40px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 500px;
    height: 500px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 28px;
  }


  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 164, 164);
    font-size: 55px;
    font-family: 'Caveat', cursive;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Catamaran', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    font-family: 'Catamaran', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 20px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  table {
    border-collapse: collapse;
    width: 90%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .tableMain {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  table img {
    width: 100px;
    height: 100px;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #4d4d4d86;
    color: #ffffff;

    font-size: 18px;
    position: relative;
  }

  tr:nth-child(even) {
    background-color: #4d4d4d86;
  }

  th {
    background-color: #202020;
    color: #2491f7;
    font-size: 20px;
  }

  /* Show the hover images when hovering over the table cells */
  .hoverDiv img {
    width: 200px;
    height: 200px;
  }

  .hoverDiv {
    display: flex;
    justify-content: space-evenly;
  }

  .sticky {
    width: 20%;
  }

  .stickyDiv {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;

  }
}

@media screen and (min-width: 3840px) {
  .connect2-2-Mob {
    display: none;
  }

  .btnfos-0-3-mob {
    display: none;
  }

  .details {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    background-color: #252525bb;
    padding: 15px;
    border-radius: 10px;
  }

  .musicOn {
    position: -webkit-sticky;
    position: sticky;
    bottom: 300px;
    width: 200px;
    height: 200px;
    margin-left: 100px;
    cursor: pointer;
    -webkit-animation: bounce 3s ease infinite;
            animation: bounce 3s ease infinite;
  }


  .rock {
    position: -webkit-sticky;
    position: sticky;
    bottom: 10%;
    width: 15%;
    margin-left: 30px;
  }

  .stickyimg {
    position: -webkit-sticky;
    position: sticky;
    bottom: 80px;
    width: 30%;
    margin-left: 70%;
    justify-content: flex-end;
    -webkit-animation: floating 2.5s ease-in-out infinite;
            animation: floating 2.5s ease-in-out infinite;
  }

  .stickyGuitar {
    /* Make the image sticky at the bottom */
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 100%;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .priceBox {
    background-color: #00000075;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    padding: 30px;
    border-radius: 40px;
    border: 6px dashed #eb2f0e;
  }

  .quotes {
    color: #ebc95a;
    font-size: 70px;
    font-family: 'Caveat', cursive;
  }

  .prices {
    text-align: center;
    color: #b4e3ff;
    font-size: 60px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .pricesN {
    text-align: center;
    color: rgba(207, 207, 207, 0.61);
    font-style: italic;
    font-size: 24px;
  }

  .poly {
    width: 25px;
    height: 25px;
    margin-left: 10px;

  }

  .note {
    text-align: center;
    color: red;
    font-size: 20px;
  }

  .mainImg {
    width: 40%;
    height: 40%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 43px;
    font-family: 'Catamaran', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 157, 157);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 85px;
    margin-right: 85px;
  }

  .connectBtn {
    background-color: #beb41f00;
    color: rgb(255, 255, 255);
    padding: 25px;
    font-size: 44px;
    padding-left: 80px;
    padding-right: 80px;
    border-width: 3px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 10px;
    border-width: 6px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #3c9af3);
    border-image-slice: 1;
    display: flex;
  }

  .connectBtn:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: rgb(0, 0, 0);
    background: linear-gradient(315deg, rgba(0, 0, 0, 1) 0%, rgba(41, 1, 4, 1) 50%, rgba(0, 0, 0, 1) 59%, rgba(56, 0, 4, 1) 100%);
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/logo.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/logo.d1b8c411.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-bottom: 15vh;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    width: 300px;
    height: 300px;
    border-radius: 100px;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 22rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 10px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #ee1d1d, #fd3b3b);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 22rem;
    line-height: 22rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3rem;
    font-size: 90px;
    color: #fdba00;
    letter-spacing: 4px;
    font-family: 'Orbitron', sans-serif;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 10rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 10rem;
    line-height: 10rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    padding-top: 15%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/fire.b98ac362.jpg) no-repeat center center;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
    background-size: cover;
  }

  .boxWrap2Mint {

    background: rgb(61, 3, 3);
    background: radial-gradient(circle, rgba(61, 3, 3, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(13, 0, 2, 1) 100%);
    background-size: cover;
    padding-bottom: 7%;
    padding-top: 7%;

  }

  .boxWrapNFT {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #000000;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 10%;
    padding-bottom: 7%;
    background: rgb(89, 4, 35);
    background: radial-gradient(circle, rgba(89, 4, 35, 0.5178243172268908) 0%, rgba(222, 6, 86, 0.15367865896358546) 50%, rgba(0, 0, 0, 0) 100%);
  }


  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  iframe {
    height: 70vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    margin-top: 30px;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 100px;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 40px;
    border: 2px solid transparent;
    -webkit-animation: borderGlow 2s infinite;
            animation: borderGlow 2s infinite;
    border-width: 7px;
    border-style: solid;
    border-image: linear-gradient(to right, #d01622, #ff5c5c);
    border-image-slice: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 142, 236);
    background: linear-gradient(117deg, rgba(0, 142, 236, 0.09485512955182074) 0%, rgba(0, 0, 0, 0.2573201155462185) 50%, rgba(24, 4, 7, 1) 100%);
  }

  .about2-2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  @-webkit-keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Initial glow state */
      border-color: transparent;
      /* Initial border color */
    }

    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
      /* Max glow state at 50% */
      border-color: transparent;
      /* Keep the border transparent */
    }

    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
      /* Return to initial glow state */
      border-color: transparent;
      /* Keep the border transparent */
    }
  }

  .conT {
    font-size: 190px;
    color: rgb(255, 255, 255);
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 20px #ffffff;
  }

  .conT2 {
    font-size: 190px;
    color: white;
    text-align: center;
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 20px #ffffff;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Catamaran', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .listContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .listContainer2 {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .listMain {
    margin-bottom: 8%;
    margin-left: auto;
    justify-content: center;
    width: 90%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: -5px 5px black;
    border: 1px solid black;
    padding: 20px;
    transition: box-shadow 0.3s ease;
  }

  .listMain:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  .listH {
    font-size: 23px;
    color: #ffd415;
    letter-spacing: 2px;
    text-align: center;
    text-shadow: 0 0 10px #e6414198;
  }

  .listCon {
    font-size: 18px;
    color: rgb(253, 245, 175);

    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .in1 {
    font-family: 'Orbitron', sans-serif;
    text-align: left;
    font-size: 150px;
    color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff9c;
  }

  .in2 {
    text-align: left;
    font-size: 90px;
    color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 5%;
    font-family: 'Caveat', cursive;
    line-height: 110px;

  }

  .button-85 {
    padding: 0.8em 2.5em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 20px;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 60px;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #002bff,
        #7a00ff,
        #ff00c8,
        #ff0000);
    position: absolute;
    top: -5px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .introduction2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .hl {
    font-family: 'Orbitron', sans-serif;
    color: #ffffff;
  }

  .download {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
  }

  .explore {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 30px;
    border: none;
    background-color: #0084ff;
    box-shadow: -3px 3px #01519c;
    color: white;
    border-radius: 100px;
    padding: 10px;
  }

  .explore:hover {
    -webkit-animation: shake 0.3s ease-in-out;
            animation: shake 0.3s ease-in-out;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    50% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }

    75% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .storyCon {
    font-size: 50px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
  }

  .nameT {
    font-size: 60px;
    color: rgb(250, 48, 48);
    text-align: center;
    margin-top: 70px;
  }

  .positionT {

    color: rgb(255, 255, 255);
    text-align: center;
    letter-spacing: 1px;
    font-size: 45px;
  }

  .teamMain {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .teamMain img {
    width: 800px;
    border-radius: 100%;
    border: 4px #964835 solid;
    border: 6px #964835 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  @-webkit-keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 20px 10px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 40px 20px #dc0513a4;
    }
  }

  @keyframes glow-in-out {

    0%,
    100% {
      box-shadow: 0 0 20px 10px #dc0513a4;
    }

    50% {
      box-shadow: 0 0 40px 20px #dc0513a4;
    }
  }


  .stripe {
    width: 100%;
  }

  .storyCon p {
    color: white;
    margin-top: 55px;
    margin-bottom: 55px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 20px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 1px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .type {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 5%;
  }

  .bgC {
    background-color: #141414;
    border-radius: 50px;
    padding: 30px;
    border: 1px solid white;
    box-shadow: rgba(243, 230, 224, 0.5) 0 3px 8px;
  }

  .typeCon {
    color: rgb(253, 175, 175);
    font-size: 60px;
    width: 80%;
    margin-bottom: 90px;
    margin-top: 60px;
  }

  .typeH {
    font-size: 75px;
  }

  .imagesNormal {
    justify-content: space-evenly;
    width: 100%;
  }

  .imagesNormalDiv {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
  }

  .imagesNormal img {
    width: 450px;
    height: 450px;
    margin-bottom: 20px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .imagesNormal img:hover {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    margin-top: 2%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border: 6px solid white;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 60%;
  }

  .selectedImg2 {
    background-image: url('https://gif-4qi.pages.dev/images/gif.gif');
    background-size: contain;
    width: 1000px;
    height: 1000px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    z-index: 10000000;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .price {
    text-align: center;
    font-size: 60px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    letter-spacing: 2px;
    cursor: pointer;
  }

  .price:hover {
    color: #0084ff;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 80px;
    margin-left: 110px;
    margin-right: 110px;
    width: 210px;
    height: 210px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #ffee0000;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: #ffffff 6px solid;
    background-color: #ffee0000;
    transition: box-shadow 0.3s ease;
  }

  .btnfos-0-2:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }


  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 25px;
    font-size: 75px;
    width: 800px;
    background-color: #ffee0000;
    border: 2.5px solid #ffffff;
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
    font-family: 'Catamaran', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: box-shadow 0.3s ease;
  }


  /* CSS */
  .button-42 {
    background-color: transparent;
    background-color: initial;
    background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
    height: 40px;
    line-height: 40px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
    transition: box-shadow .2s;
  }

  .button-42:hover {
    box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
  }

  .btnfos-0-3:hover {
    box-shadow: none;
    /* Apply "display: none" effect smoothly */
  }

  #dogSelect1 {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 40px;
    font-size: 42px;
    background-color: #000000ab;
    color: white;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    cursor: pointer;
  }

  #dogSelect {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 30px;
    font-size: 25px;
    background-color: #000000ab;
    color: white;

    width: 45%;
    margin-left: auto;
    margin-right: auto;
    border: 1px rgba(220, 220, 220, 0.425) solid;
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 700px;
    /* Set a maximum height for the container */
    -webkit-animation-name: pop;
            animation-name: pop;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  @-webkit-keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes pop {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .down {
    width: 60px;
    height: 60px;
    margin-left: 20px;

  }

  #dogSelect img {
    width: 130px;
    height: 130px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  #dogSelect div {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 40px;

    margin-top: auto;
    margin-bottom: auto;
  }

  .textH:hover {
    color: #009ab8;
    cursor: pointer;
  }

  .selectImg {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .selectImg:hover {
    background-color: rgba(128, 128, 128, 0.644);
  }

  select {}


  .nftamount {
    color: #ffffff;
    font-size: 280px;
    font-family: 'Catamaran', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #17ff1e;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 50px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 40px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 60px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 60px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Catamaran', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 60px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 55px;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 160px;
    height: 160px;
    margin-left: 40px;
    margin-right: 40px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 700px;
    height: 700px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 40px;
    margin-bottom: 50px;
  }


  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 164, 164);
    font-size: 90px;
    font-family: 'Caveat', cursive;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Catamaran', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    font-family: 'Catamaran', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 20px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  table {
    border-collapse: collapse;
    width: 90%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .tableMain {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  table img {
    width: 100px;
    height: 100px;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #4d4d4d86;
    color: #ffffff;

    font-size: 18px;
    position: relative;
  }

  tr:nth-child(even) {
    background-color: #4d4d4d86;
  }

  th {
    background-color: #202020;
    color: #2491f7;
    font-size: 20px;
  }

  /* Show the hover images when hovering over the table cells */
  .hoverDiv img {
    width: 200px;
    height: 200px;
  }

  .hoverDiv {
    display: flex;
    justify-content: space-evenly;
  }

  .sticky {
    width: 20%;
  }

  .stickyDiv {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;

  }
}
